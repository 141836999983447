import { WarningTwoIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Text,
  useColorModeValue,
  AlertProps,
  useBreakpoint,
} from '@chakra-ui/react'
import { CSSProperties, FC } from 'react'

type AlertBannerProps = {
  text: string
  status: 'error' | 'info' | 'warning' | 'success'
  top?: AlertProps['top']
  bgColor?: string
  actionText?: string
  actionFn?: () => void
  ref?: React.ForwardedRef<any>
}

export const AlertBanner: FC<AlertBannerProps> = ({
  text,
  status = 'error',
  top = ['3.5rem', '5.5rem'],
  bgColor,
  actionText,
  actionFn,
}) => {
  const bp = useBreakpoint()
  const isMobile = bp === 'base' || bp === 'sm' || bp === 'md'

  return (
    <Alert
      status={status}
      style={{
        textAlign: 'center',
        position: 'sticky',
        boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.2)',
        marginTop: 0,
        zIndex: 3,
      }}
      top={top}
      variant={useColorModeValue('subtle', 'solid')}
      bgColor={bgColor}
    >
      <AlertTitle>
        <Text display="inline">
          {status === 'error' && (
            <WarningTwoIcon
              color="#C41E3A"
              style={{ marginRight: '8px', marginBottom: '2px' }}
            />
          )}
          {status === 'error' ||
            (status === 'warning' && (
              <WarningTwoIcon
                color="#C41E3A"
                style={{ marginRight: '8px', marginBottom: '2px' }}
              />
            ))}

          {status === 'success' && (
            <Text marginRight="8px" marginBottom="2px">
              🎉
            </Text>
          )}
          {text}
        </Text>

        <Text
          display={isMobile ? 'inline-block' : 'inline'}
          onClick={actionFn}
          fontWeight="bold"
          textDecoration="underline"
          cursor="pointer"
          marginLeft="8px"
        >
          {actionText}
        </Text>
      </AlertTitle>

      {status === 'success' && <Text marginRight="8px">🎉</Text>}
    </Alert>
  )
}
