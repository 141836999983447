import { FC, useEffect } from 'react'
import {
  Box,
  Divider,
  Flex,
  ModalBody,
  ModalHeader,
  Text,
  useBreakpoint,
} from '@chakra-ui/react'
import { Modal } from './Modal'
import { useDisplayWithinTimeframe } from '@/utils/hooks/useDisplayWithinTimeframe'
import Cookies from 'js-cookie'

type PriceIncreaseModalProps = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

const PRICE_INCREASE_MODAL_COOKIE = 'priceIncreaseModalSeen'

export const PriceIncreaseModal: FC<PriceIncreaseModalProps> = ({
  isOpen,
  onOpen,
  onClose,
}: PriceIncreaseModalProps) => {
  // Note that months are 0-indexed
  const isWithinTimeframe = useDisplayWithinTimeframe(
    new Date(2024, 10, 18),
    new Date(2024, 11, 3)
  )
  const hasBeenSeen = Cookies.get(PRICE_INCREASE_MODAL_COOKIE) === 'true'
  const shouldAutoPopup = isWithinTimeframe && !hasBeenSeen

  const bp = useBreakpoint()
  const isMobile = bp === 'base' || bp === 'sm' || bp === 'md'

  useEffect(() => {
    if (shouldAutoPopup) {
      onOpen()
      Cookies.set(PRICE_INCREASE_MODAL_COOKIE, 'true', { expires: 14 }) // Expires in 14 days
    }
  }, [shouldAutoPopup])

  return (
    <Modal
      header={
        <ModalHeader paddingBottom={0}>
          <Text
            textStyle="h2"
            color="alert.primary"
            textTransform="uppercase"
            textAlign="center"
          >
            Price Increase
          </Text>
        </ModalHeader>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBody paddingBottom="1rem">
        <Flex
          flexDirection="column"
          rowGap={4}
          color="light.container.text"
          fontSize="1rem"
          textTransform="uppercase"
          textAlign="center"
          textStyle="h2"
        >
          <Text>
            We're a small burger shop but ingredient costs are only getting
            bigger
          </Text>
          <Text> We've held out as long as we could!</Text>
          <Box>
            <Text>As of Tuesday 11/19,</Text>
            <Text>our menu prices have increased</Text>
          </Box>

          <Divider
            orientation="horizontal"
            marginTop={1}
            marginBottom={2}
            borderColor="light.container.text"
            borderBottomWidth={3}
            opacity="100%"
          />

          <Text> Friendly reminder </Text>
          <Box>
            <Text>We still cut, grind, and portion our meat;</Text>
            <Text
              {...(isMobile
                ? {}
                : {
                    whiteSpace: 'nowrap',
                    style: { textIndent: -10 },
                  })}
            >
              and make our ranch, pickles, and jalapenos
            </Text>
          </Box>

          <Box>
            <Text>No shortcuts being taken</Text>
            <Text>still a lot of work that</Text>
            <Text>goes behind your burgers</Text>
          </Box>

          <Box marginTop={6}>
            <Text>We appreciate your understanding</Text>
            <Text>and look forward to serving you soon!</Text>
          </Box>
        </Flex>
      </ModalBody>
    </Modal>
  )
}
