import { useEffect, useState } from 'react'

export function useDisplayWithinTimeframe(start: Date, end: Date) {
  const [shouldDisplay, setShouldDisplay] = useState(false)

  useEffect(() => {
    const now = new Date()

    // Check if the current date is within the start and end date range
    const isWithinTimeframe = now >= start && now <= end

    setShouldDisplay(isWithinTimeframe)
  }, [start, end])

  return shouldDisplay
}
