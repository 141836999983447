import {
  Modal as ChakraModal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { FC } from 'react'

type ModalProps = {
  header?: React.ReactNode
  children?: React.ReactNode | undefined
  isOpen?: boolean
  onClose: () => void
  ref?: React.ForwardedRef<any>
}

export const Modal: FC<ModalProps> = ({
  header,
  children,
  isOpen,
  onClose,
}) => {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bgColor="light.container.background" padding={4}>
        {header}
        <ModalCloseButton color="light.container.text" />
        {children}
      </ModalContent>
    </ChakraModal>
  )
}
